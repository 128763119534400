<template>
<div>
    <v-container class="pt-0 pb-0">
        <v-row>
            <v-col lg="12" class="pb-1">
                <s-crud noFull searchInput :filter="filterPosition" no-border height="auto" @rowSelected="rowSelected($event)" title="Cargos" :config="this.config" @save="save($event)" :add="isAdmin" edit :remove="isAdmin">
                    <template v-slot:filter>
                        <div>
                            <v-col>
                                <v-row justify="center">
                                    <v-col lg="3" class="s-">
                                        <c-select-area clearable noDefault full label="Filtro por Área" v-model="filterPosition.AreID"> </c-select-area>
                                    </v-col>

                                </v-row>
                            </v-col>
                        </div>
                    </template>

                    <template scope="props">
                        <v-container v-if="props.item != null">
                            <v-row justify="center">
                                <v-col lg="8" md="8"  class="s-col-form">
                                    <s-text autofocus v-model="props.item.PstName" label="Nombre"></s-text>
                                </v-col>
                                <!-- <v-col lg="3" md="4"   class="s-col-form">
                                    <s-text v-model="props.item.PstTelephone" label="Nro. Celular Asignado"></s-text>
                                </v-col> -->
                            </v-row>

                            <v-row justify="center">
                                <v-col lg="4" md="6" class="s-col-form">
                                    <s-text v-model="props.item.PstAbbreviation" label="Abreviatura"></s-text>
                                </v-col>
                                <v-col lg="4" md="6" class="s-col-form">
                                    <c-select-area  v-model="props.item.AreID" :text="props.item.Area" label="Sub-Área"></c-select-area>
                                </v-col>

                            </v-row>
                            <v-row justify="center">
                                <v-col lg="8" md="12" class="s-col-form">
                                    <s-textarea label="Objetivo" v-model="props.item.PstObjective"></s-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </template>

                    <template v-slot:PstStatus="{ row }">
                        <v-chip style="margin:0px" x-small :color="row.PstStatus == 1 ? 'success' : 'error'">
                            {{ row.PstStatus == 1 ? "Activo" : "Baja" }}
                        </v-chip>
                    </template>

                </s-crud>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import Service from "@/services/General/PositionService";
export default {
    name: "Position",
    components: {},
    mounted() {},
    data: () => ({
        filterPosition: {
            AreID: null
        },
        selected: [],
        config: {
            model: {
                PstName: "string",
                PstAbbreviation: "string",
                PstObjective: "string",
                AreID: "number",
                UsrCreateID: "number",
                PstID: "ID",
                SecStatus: "boolean",
                PstStatus: "",
                PstTelephone: "string"
            },
            title: "Cargos",
            service: Service,
            headers: [{
                    text: "ID",
                    value: "PstID"
                },
                {
                    text: "Cargo",
                    value: "PstName"
                },
                {
                    text: "Área",
                    value: "Area"
                },
                /* {
                    text: "Nro. Celular Asig.",
                    value: "PstTelephone"
                }, */
                {
                    text: "Estado",
                    value: "PstStatus"
                },
            ],
        },

        parentID: 0,
        isAdmin: false,
    }),
    methods: {

        save(item) {
            if (item.PstName.trim().length <= 0) {
                this.$fun.alert("Complete Nombre de Cargo", "warning");
                return;
            }
            if (item.TypePosition == 0) {
                this.$fun.alert("Seleccione Tipo de cargo", "warning");
                return;
            }
            /* if (item.AreID == 0) {
                this.$fun.alert("Seleccione Área", "warning");
                return;
            } */
            item.PstName = item.PstName.toUpperCase();
            item.PstAbbreviation = item.PstAbbreviation.toUpperCase();
            item.save();
        },

        rowSelected(rows) {
            if (rows.length > 0) {
                this.parentID = rows[0].PstID;
            }
        },
    },
    watch: {

    },
    created() {
        this.isAdmin = this.$fun.getSecurity().IsLevelAdmin;
    },
};
</script>

<style></style>
