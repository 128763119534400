import Vue from "vue";

const menu = "position/";

export default {
    pagination(dtr) {
        return Vue.prototype.$http.post(menu + "positions", dtr);
    },

    save(obj, usr) {
        return Vue.prototype.$http.post(menu + "create", obj, {
            params: { usrID: usr },
        });
    },
};